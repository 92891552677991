import { Entry } from 'contentful';
import { NextRouter } from 'next/router';
import { FeaturePageModel } from 'repos/featurePage';
import { IntegrationPageModel } from 'repos/integrationPage';
import { UseCases } from 'repos/solutionPage';

export function getLinks({
  router,
  t,
  menuParams,
}: {
  router: NextRouter;
  t: (key: string) => string;
  menuParams?: {
    useCases: Entry<UseCases>[];
    features: Entry<FeaturePageModel>[];
    integrations: Entry<IntegrationPageModel>[];
  };
}) {
  const { useCases, features, integrations } = menuParams || {
    useCases: [],
    features: [],
    integrations: [],
  };

  const platformButtonContent = {
    navTitle: t('b12_platform'),
    overview: {
      title: t('b12_platform'),
      description: t(t('b12_platform_description')),
      buttonTitle: t('overview'),
      buttonLink: '/b12',
      isSelected: router.pathname.split('/')[1] === 'b12',
    },
    firstColumn: {
      isSelected: router.pathname.split('/')[1] === 'features',
      title: t('feature_plural'),
      link: '/features',
      items: features.map((feature) => ({
        isSelected: router.asPath.split('/')[2] === feature.fields.slug,
        title: feature.fields.title,
        link: `/features/${feature.fields.slug}`,
        id: feature.sys.id,
      })),
    },
    secondColumn: {
      isSelected: router.pathname.split('/')[1] === 'integrations',
      title: t('integration_plural'),
      link: '/integrations',
      items: integrations.map((integration) => ({
        isSelected: router.asPath.split('/')[2] === integration.fields.slug,
        title: integration.fields.title,
        link: `/integrations/${integration.fields.slug}`,
        id: integration.sys.id,
      })),
    },
  };

  const solutionsButtonContent = {
    navTitle: t('solutions'),
    overview: {
      title: t('solutions'),
      description: t('solution_description'),
      buttonTitle: t('why_b12'),
      buttonLink: '/why-b12',
      isSelected: router.pathname.split('/')[1] === 'why-b12',
    },
    firstColumn: {
      isSelected: router.pathname.split('/')[1] === 'use-cases',
      title: t('use_cases'),
      link: '/use-cases',
      items: useCases
        .filter((u) => !u.fields.isIndustry)
        .map((useCase) => ({
          isSelected: router.asPath.split('/')[2] === useCase.fields.slug,
          title: useCase.fields.title,
          link: `/use-cases/${useCase.fields.slug}`,
          id: useCase.sys.id,
        })),
    },
    secondColumn: {
      isSelected: router.pathname.split('/')[1] === 'industries',
      title: t('industry'),
      link: '/industries',
      items: useCases
        .filter((u) => u.fields.isIndustry)
        .map((useCase) => ({
          isSelected: router.asPath.split('/')[2] === useCase.fields.slug,
          title: useCase.fields.title,
          link: `/industries/${useCase.fields.slug}`,
          id: useCase.sys.id,
        })),
    },
  };

  const resourcesButtonContent = {
    navTitle: t('resource_plural'),
    isSelected: router.pathname === '/blog',
    items: [
      {
        isSelected: router.pathname === '/blog',
        title: t('blog'),
        link: '/blog',
        id: 'blog',
      },
      {
        isSelected: router.pathname === '/releases',
        title: t('releases'),
        link: '/releases',
        id: 'releases',
      },
    ],
  };

  const companyButtonContent = {
    navTitle: t('company'),
    isSelected: ['/about', '/contact'].includes(router.pathname),
    items: [
      {
        isSelected: router.pathname === '/about',
        title: t('about'),
        link: '/about',
        id: 'about',
      },
      {
        isSelected: router.pathname === '/contact',
        title: t('contact_us'),
        link: '/contact',
        id: 'contact',
      },
    ],
  };

  return {
    platformButtonContent,
    solutionsButtonContent,
    resourcesButtonContent,
    companyButtonContent,
  };
}
