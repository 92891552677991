import { Entry } from 'contentful';
import { FeaturePageModel } from 'repos/featurePage';
import { IntegrationPageModel } from 'repos/integrationPage';
import { UseCases } from 'repos/solutionPage';

import Header from './Header/header';
import Footer from './footer';

type Props = {
  color?: 'light' | 'dark';
  stickyHeader?: boolean;
  children: React.ReactNode;
  overridePath?: string;
  menuParams: {
    useCases: Entry<UseCases>[];
    features: Entry<FeaturePageModel>[];
    integrations: Entry<IntegrationPageModel>[];
  };
};

export default function Layout({
  children,
  color = 'dark',
  overridePath,
  stickyHeader,
  menuParams,
}: Props) {
  return (
    <div className="flex min-h-screen flex-col">
      <Header
        color={color}
        overridePath={overridePath}
        sticky={stickyHeader}
        menuParams={menuParams}
      />
      <main className="overflow-x-hidden">{children}</main>
      <Footer overridePath={overridePath} />
    </div>
  );
}
