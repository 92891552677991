import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { getUrl } from 'utils/env';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  schema?: { [key: string]: any };
};

export default function SEO(props: Props) {
  const { t } = useTranslation();
  const { locale, asPath } = useRouter();

  const siteUrl = getUrl();
  const title = props.title ? `${props.title} – Apprentx` : 'Apprentx';
  const description = props.description || t('hero_text');
  const image = props.image
    ? `${props.image}?w=1200&h=630&q=80&fit=thumb&f=face&fm=jpg`
    : `${siteUrl}/seo.png`;
  const url = `${siteUrl}/${locale}${asPath}`;
  const keywords = t('keywords');

  const defaultSchema = {
    '@context': 'http://schema.org',
    '@type': 'Website',
    url,
    name: title,
    description,
    author: {
      '@type': 'Organization',
      name: 'Apprentx',
      url: siteUrl,
    },
    creator: ['Apprentx'],
    publisher: {
      '@type': 'Organization',
      name: 'Apprentx',
      url: siteUrl,
    },
    image,
    mainEntityOfPage: url,
  };

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content="Apprentx" />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="Apprentx" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:creator" content="apprentxb12" />
      <meta name="twitter:site" content="apprentxb12" />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(props.schema || defaultSchema),
        }}
      />
    </Head>
  );
}
