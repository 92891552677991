import { i18n } from 'lib/i18n';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { getLinks } from 'utils/links';

import FooterColumn from './FooterColumn';
import FooterMobileColumn from './FooterMobileColumn';
import LocaleSelector from './localeSelector';
import FacebookIcon from './svgs/facebook';
import InstagramIcon from './svgs/instagram';
import LinkedinIcon from './svgs/linkedin';
import Signature from './svgs/signature';

export type FooterColumnData = {
  title: string;
  items: { title: string; href: string }[];
  rootUrl?: string;
};

type Props = {
  overridePath?: string;
};

export default function Footer({ overridePath }: Props) {
  const { t } = useTranslation();
  const locale = i18n.language;
  const router = useRouter();

  const {
    platformButtonContent,
    solutionsButtonContent,
    resourcesButtonContent,
    companyButtonContent,
  } = getLinks({
    router,
    t,
  });

  const laFamilleEdgendaColumns: FooterColumnData[] = [
    {
      title: 'Edgenda',
      rootUrl: t('edgenda_root_url') as string,
      items: [
        { title: t('strategy_operations'), href: t('edgenda_strategy_url') },
        { title: t('coaching_leadership'), href: t('edgenda_coaching_url') },
        {
          title: t('emerging_technologies'),
          href: t('edgenda_technology_url'),
        },
        {
          title: t('learning'),
          href: t('edgenda_learning_url'),
        },
      ],
    },
    {
      title: t('afi_by_edgenda'),
      rootUrl: t('afi_by_edgenda_root_url') as string,
      items: [
        {
          title: t('design_development'),
          href: t('afi_by_edgenda_learning_experiences_url'),
        },
        {
          title: t('rapid_elearning'),
          href: t('afi_by_edgenda_rapid_elearning_url'),
        },
        {
          title: t('custom_programs'),
          href: t('afi_by_edgenda_custom_programs_url'),
        },
        {
          title: t('business_coaching'),
          href: t('afi_by_edgenda_business_coaching_url'),
        },
      ],
    },
    {
      title: t('apprentx_by_edgenda'),
      rootUrl: `/${locale}`,
      items: [{ title: t('b12'), href: t('/b12') }],
    },
    {
      title: t('afiu'),
      rootUrl: t('afiu_root_url') as string,
      items: [
        { title: t('for_individuals'), href: t('afiu_individuals_url') },
        { title: t('for_organizations'), href: t('afiu_organizations_url') },
        { title: t('live_afiu'), href: t('afiu_live_afiu_url') },
      ],
    },
  ];

  const apprentxFooterColumns = [
    ...[platformButtonContent, solutionsButtonContent].map((button) => ({
      id: button.navTitle,
      title: button.navTitle,
      items: [
        {
          title: button.firstColumn.title,
          href: button.firstColumn.link,
        },
        {
          title: button.secondColumn.title,
          href: button.secondColumn.link,
        },
      ],
    })),

    ...[resourcesButtonContent, companyButtonContent].map((button) => ({
      id: button.navTitle,
      title: button.navTitle,
      items: button.items.map(({ title, link }) => ({
        title,
        href: link,
      })),
    })),
  ];

  return (
    <footer className="mx-0 flex md:mx-auto">
      <div className="mx-6 w-full max-w-7xl py-14">
        <div className="mb-8 hidden flex-row flex-wrap items-end md:flex">
          <LocaleSelector
            overridePath={overridePath}
            className="ml-auto hover:underline"
          />
        </div>
        <div className="hidden md:block">
          <div className="flex flex-row items-start">
            <div className="mr-7 flex w-[220px] items-start">
              <Link href="/">
                <a aria-label={t('home')}>
                  <Signature color="#282828" height={32} locale={locale} />
                </a>
              </Link>
            </div>
            <div className="flex flex-row flex-wrap gap-7">
              {apprentxFooterColumns.map((column) => (
                <FooterColumn key={column.id} column={column} />
              ))}
            </div>
          </div>
          <hr className="mt-16 mb-12" />
        </div>
        <div className="flex flex-col items-start gap-2 md:hidden">
          {apprentxFooterColumns.map((column) => (
            <FooterMobileColumn key={column.id} column={column} />
          ))}
        </div>
        <div className="flex flex-col items-center gap-6 md:hidden">
          <hr className="mt-4 mb-6 w-full" />
        </div>
        <h2 className="text-r-2xl mb-2 block md:hidden">
          {t('edgenda_family')}
        </h2>
        <div className="hidden flex-row items-start md:flex">
          <div className="mr-7 min-w-[220px]">
            <h2 className="text-r-2xl max-w-[12ch]">{t('edgenda_family')}</h2>
          </div>
          <div className="flex flex-row flex-wrap gap-7">
            {laFamilleEdgendaColumns.map((column) => (
              <FooterColumn key={column.title} column={column} />
            ))}
          </div>
        </div>
        <div className="flex flex-col items-start gap-2 md:hidden">
          {laFamilleEdgendaColumns.map((column) => (
            <FooterMobileColumn key={column.title} column={column} />
          ))}
          <hr className="mt-4 mb-6 w-full" />
          <LocaleSelector
            overridePath={overridePath}
            className="text-black hover:text-black hover:underline"
          />
        </div>
        <div className="mt-6 flex flex-col items-center md:mt-28 md:items-start">
          <div className="flex flex-col gap-8 md:flex-row">
            <SocialButton
              href="https://www.linkedin.com/company/apprentx/"
              name="LinkedIn"
            >
              <LinkedinIcon width={24} height={24} />
            </SocialButton>
            <SocialButton
              href="https://www.facebook.com/apprentx/"
              name="Facebook"
            >
              <FacebookIcon width={24} height={24} />
            </SocialButton>
            <SocialButton
              href="https://www.instagram.com/apprentx/"
              name="Instagram"
            >
              <InstagramIcon width={24} height={24} />
            </SocialButton>
          </div>
          <hr className="mb-3 mt-6 w-0 md:w-full" />
          <div className="mt-2 flex flex-col items-center space-y-2 text-center text-gray-500 md:mt-0 md:flex-row md:space-y-0 md:space-x-8">
            <p className="text-center text-sm text-gray-500">
              {t('copyright', { year: new Date().getFullYear() })}
            </p>
            <Link href="/privacy-policy">
              <a className="text-sm">{t('privacy_policy')}</a>
            </Link>
            <Link href="/terms-and-conditions">
              <a className="text-sm">{t('terms_and_conditions')}</a>
            </Link>
            <a className="cky-banner-element cursor-pointer text-sm">
              {t('cookie_settings')}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

type SocialButtonProps = {
  name: string;
  href: string;
  children?: React.ReactNode;
};

function SocialButton({ name, href, children }: SocialButtonProps) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="inline-block text-black"
      aria-label={name}
    >
      {children}
    </a>
  );
}
